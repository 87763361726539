import React from "react"

import "./ValueItem.scss"

export default function ValueItem({ title, description, icon, alt }) {
  return (
    <div className="value-item">
      <img src={icon} alt={alt} />
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  )
}
