export function jobFilter(
  input,
  departmentFilter,
  typeFilter,
  jobs,
  searchResult,
  setSearchResult
) {
  let filtered
  if (input) {
    if (!departmentFilter && !typeFilter) {
      filtered = jobs?.edges?.filter(job => {
        return job?.node?.title?.toLowerCase().includes(input)
      })
    } else if (departmentFilter && !typeFilter) {
      filtered = jobs?.edges?.filter(job => {
        return (
          job?.node?.department?.departmentTitle.toLowerCase() ===
            departmentFilter?.toLowerCase() &&
          job?.node?.title?.toLowerCase().includes(input)
        )
      })
    } else if (!departmentFilter && typeFilter) {
      filtered = jobs?.edges?.filter(job => {
        return (
          job?.node?.type?.name?.toLowerCase() === typeFilter?.toLowerCase() &&
          job?.node?.title?.toLowerCase().includes(input)
        )
      })
    } else {
      filtered = jobs?.edges?.filter(job => {
        return (
          job?.node?.department?.departmentTitle.toLowerCase() ===
            departmentFilter.toLowerCase() &&
          job?.node?.type?.name?.toLowerCase() === typeFilter?.toLowerCase() &&
          job?.node?.title?.toLowerCase().includes(input)
        )
      })
    }
  } else if (!input) {
    if (!departmentFilter && !typeFilter) {
      filtered = jobs?.edges?.filter(job => {
        return (
          job?.node?.department?.departmentTitle.toLowerCase() ===
            departmentFilter.toLowerCase() ||
          job?.node?.type?.name?.toLowerCase() === typeFilter?.toLowerCase()
        )
      })
    } else if (departmentFilter && !typeFilter) {
      filtered = jobs?.edges?.filter(job => {
        return (
          job?.node?.department?.departmentTitle.toLowerCase() ===
          departmentFilter?.toLowerCase()
        )
      })
    } else if (!departmentFilter && typeFilter) {
      filtered = jobs?.edges?.filter(job => {
        return (
          job?.node?.type?.name?.toLowerCase() === typeFilter?.toLowerCase()
        )
      })
    } else {
      filtered = jobs?.edges?.filter(job => {
        return (
          job?.node?.department?.departmentTitle.toLowerCase() ===
            departmentFilter.toLowerCase() &&
          job?.node?.type?.name?.toLowerCase() === typeFilter?.toLowerCase()
        )
      })
    }
  }

  if (!departmentFilter && !typeFilter && !input) {
    setSearchResult(jobs?.edges)
  } else {
    setSearchResult(filtered)
  }
}
