import React from "react"
import Filter from "../Filter/Filter"

import "./Search.scss"

export default function Search({
  clearFilters,
  keyword,
  setKeyword,
  t,
  departments,
  jobTypes,
  onChangeHandler1,
  onChangeHandler2,
}) {
  return (
    <form className="search-bar" role="search" id="search">
      <input
        className="search-button"
        type="submit"
        value=" "
        aria-label="Search"
      />
      <label className="sr-only" htmlFor="search-input">
        Search
      </label>
      <input
        name="search"
        id="search-input"
        type="search"
        placeholder={t("Careers.search")}
        value={keyword}
        onChange={setKeyword}
      />
      <Filter
        clearFilters={clearFilters}
        departments={departments}
        jobTypes={jobTypes}
        onChangeHandler1={onChangeHandler1}
        onChangeHandler2={onChangeHandler2}
      />
    </form>
  )
}
