import React from "react"
import JobListItem from "../JobListItem/JobListItem"

import "./JobList.scss"

export default function JobList({ data }) {
  return (
    <ul className="job-list">
      {data &&
        data?.map(({ node }) => {
          if (node?.title) {
            return (
              <React.Fragment key={node?.id}>
                <JobListItem
                  slug={node?.slug}
                  title={node?.title}
                  department={node?.department?.departmentTitle}
                  type={node?.type?.name}
                />
                <hr className="job-post-divider" />
              </React.Fragment>
            )
          }
        })}
    </ul>
  )
}
