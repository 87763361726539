import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout/Layout"
import { useTranslation } from "react-i18next"

import { jobFilter } from "../../utils/jobFilter"

import ValueItem from "../../components/ValueItem/ValueItem"
import MountainIcon from "../../images/why_dayra___icon_1.png"
import BullseyeIcon from "../../images/why_dayra___icon_2.png"
import CarIcon from "../../images/why_dayra___icon_3.png"
import NetworkIcon from "../../images/why_dayra___icon_4.png"
import HotWheelIcon from "../../images/why_dayra___icon_5.png"
import SmileyIcon from "../../images/why_dayra___icon_6.png"
import LinkButton from "../../components/LinkButton/LinkButton"
import Search from "../../components/Search/Search"
import Dropdown from "../../components/Dropdown/Dropdown"
import JobList from "../../components/JobList/JobList"

import "../../styles/careers.scss"

export default function Careers({ data }) {
  const { jobs, departments, jobTypes } = data
  const { t } = useTranslation()

  const [input, setInput] = useState("")
  const [searchResult, setSearchResult] = useState([])
  const [department, setDepartment] = useState("")
  const [type, setType] = useState("")

  useEffect(() => {
    if (data) {
      setSearchResult(jobs?.edges)
    }
  }, [data, jobs?.edges])

  const updateInput = input => {
    let filtered
    if (department || type) {
      filtered = jobs?.edges?.filter(job => {
        return (
          job?.node?.title
            .toLowerCase()
            .includes(input?.target?.value?.toLowerCase()) &&
          job?.node?.department?.departmentTitle.toLowerCase() ===
            department.toLowerCase() &&
          job?.node?.type?.name?.toLowerCase() === type?.toLowerCase()
        )
      })
    } else {
      filtered = jobs?.edges?.filter(job => {
        return job?.node?.title
          .toLowerCase()
          .includes(input?.target?.value?.toLowerCase())
      })
    }
    setInput(input?.target?.value)
    setSearchResult(filtered)
  }

  //TO DO: add seo careers image

  return (
    <Layout
      title="Careers"
      description="Dayra's career page, home to the newest fintech jobs throughout Egypt and the Middle East."
    >
      <main className="careers">
        <h1>{t("Careers.whyJoinUs")}</h1>
        <ValueItem
          title={t("Careers.listTitle1")}
          description={t("Careers.listDesc1")}
          icon={MountainIcon}
          alt="Mountains with a flag situated in the middle"
        />
        <ValueItem
          title={t("Careers.listTitle2")}
          description={t("Careers.listDesc2")}
          icon={BullseyeIcon}
          alt="Bullseye"
        />
        <ValueItem
          title={t("Careers.listTitle3")}
          description={t("Careers.listDesc3")}
          icon={CarIcon}
          alt="Car on a road"
        />
        <ValueItem
          title={t("Careers.listTitle4")}
          description={t("Careers.listDesc4")}
          icon={NetworkIcon}
          alt="Network of users"
        />
        <ValueItem
          title={t("Careers.listTitle5")}
          description={t("Careers.listDesc5")}
          icon={HotWheelIcon}
          alt="A wheel erupting in flames"
        />
        <ValueItem
          title={t("Careers.listTitle6")}
          description={t("Careers.listDesc6")}
          icon={SmileyIcon}
          alt="A smiley"
        />
        <hr className="gradient-divider" />

        <h1>{t("Careers.openings")}</h1>
        <Search
          keyword={input}
          setKeyword={updateInput}
          t={t}
          departments={departments}
          jobTypes={jobTypes}
          clearFilters={() => setSearchResult(jobs?.edges)}
          onChangeHandler1={e => {
            setDepartment(e.target.value)
            jobFilter(
              input,
              e.target.value,
              type,
              jobs,
              searchResult,
              setSearchResult
            )
          }}
          onChangeHandler2={e => {
            setType(e.target.value)
            jobFilter(
              input,
              department,
              e.target.value,
              jobs,
              searchResult,
              setSearchResult
            )
          }}
        />
        <Dropdown
          htmlFor="departments"
          label="Departments"
          selectElementName="departments"
          selectElementID="departments"
          defaultOption="Department"
          options={departments}
          onChange={e => {
            setDepartment(e.target.value)
            jobFilter(
              input,
              e.target.value,
              type,
              jobs,
              searchResult,
              setSearchResult
            )
          }}
        />
        <Dropdown
          htmlFor="job-type"
          label="Job Type"
          selectElementName="job-type"
          selectElementID="job-type"
          defaultOption="Type"
          options={jobTypes}
          onChange={e => {
            setType(e.target.value)
            jobFilter(
              input,
              department,
              e.target.value,
              jobs,
              searchResult,
              setSearchResult
            )
          }}
        />
        {searchResult?.length ? (
          <JobList data={searchResult} />
        ) : !searchResult?.length && !jobs?.edges ? (
          <>
            <p className="no-jobs">
              We currently don't have any job openings, but we're always on the
              look out for talent. Please submit your details here.
            </p>
            <LinkButton variant="primary" to="/careers/apply">
              Submit CV
            </LinkButton>
          </>
        ) : (
          <p className="no-jobs">No results</p>
        )}
        <div className="apply-here">
          <p className="no-jobs">Can’t find a relevant opening?</p>
          <LinkButton variant="primary" to="/careers/apply">
            Apply Here
          </LinkButton>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($locale: String, $department: String, $type: String) {
    jobs: allContentfulJobPost(
      sort: { fields: createdAt, order: DESC }
      filter: {
        node_locale: { eq: $locale }
        department: { departmentTitle: { eq: $department } }
        type: { name: { eq: $type } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          department {
            ... on ContentfulJobDepartment {
              contentful_id
              __typename
              departmentTitle
            }
          }
          type {
            ... on ContentfulJobType {
              contentful_id
              __typename
              name
            }
          }
          location {
            ... on ContentfulJobLocation {
              contentful_id
              __typename
              location
            }
          }
        }
      }
    }
    departments: allContentfulJobDepartment(
      sort: { fields: departmentTitle, order: DESC }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          title: departmentTitle
        }
      }
    }
    jobTypes: allContentfulJobType(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          title: name
        }
      }
    }
  }
`
