import React from "react"

import Link from "../Link/Link"

import "./JobListItem.scss"

export default function JobListItem({ title, slug, department, type, id }) {
  return (
    <li className="job-list-item" key={id}>
      <Link
        to={`/careers/${slug}`}
        state={{ jobTitle: title, department, type, slug }}
      >
        <h2>{title}</h2>
      </Link>
      <ul>
        <li className="item-details">{department}</li>
        <li className="item-details"> {type}</li>
      </ul>
    </li>
  )
}
