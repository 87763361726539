import React, { useState } from "react"
import ReactDOM from "react-dom"

import Dropdown from "../Dropdown/Dropdown"

import "./Filter.scss"

export default function Filter({
  clearFilters,
  departments,
  jobTypes,
  onChangeHandler1,
  onChangeHandler2,
}) {
  const [show, setShow] = useState(false)

  return (
    <>
      {show &&
        ReactDOM.createPortal(
          <div className="filter-modal">
            <form
              onSubmit={e => {
                e.preventDefault()
                setShow(false)
              }}
            >
              <div className="form-header">
                <button
                  className="reset-filters-button"
                  type="reset"
                  onClick={() => {
                    clearFilters()
                    setShow(false)
                  }}
                >
                  <span className="filter-icon" />
                  Clear Filters
                </button>
                <button
                  className="close-filter-modal-button"
                  onClick={() => setShow(false)}
                >
                  <span>Close Modal</span>
                </button>
              </div>
              <Dropdown
                htmlFor="departments"
                label="Departments"
                selectElementName="departments"
                selectElementID="departments"
                defaultOption="Department"
                options={departments}
                onChange={onChangeHandler1}
              />
              <Dropdown
                htmlFor="job-type"
                label="Job Type"
                selectElementName="job-type"
                selectElementID="job-type"
                defaultOption="Type"
                options={jobTypes}
                onChange={onChangeHandler2}
              />
              <input
                className="apply-filters-button"
                type="submit"
                value="Apply Filters"
              />
            </form>
          </div>,
          document.getElementById("home")
        )}

      <button
        type="button"
        className="filter-button"
        onClick={() => setShow(true)}
      >
        <span className="sr-only">Filter Jobs</span>
      </button>
    </>
  )
}
